import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import IconButton from '@material-ui/core/IconButton';

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Content from "../components/Content";
import ContentJustified from "../components/ContentJustified";
import Container from "@material-ui/core/Container";
import HeaderSection from "../components/HeaderSection";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Button from "@material-ui/core/Button";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import GitHubIcon from "@material-ui/icons/GitHub";
const useStyles = makeStyles((theme) => ({
  image: {
    maxWidth: 500,
  },
  title: {
    color: theme.palette.primary.main,
    padding: theme.spacing(2),
  }
}));

const AlumniPostTemplate = ({

  content,
  contentComponent,
  showPhoto,
  title,
  date,
  image,
  linkedIn,
  github,
  cohort,
  location,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const PostContent = contentComponent || Content;
  const linkedinShare = () => {
    const newWindow = window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${location.href}`,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };
  const facebookShare = () => {
    const newWindow = window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${location.href}`,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  return (
    <React.Fragment>
      <Container maxWidth="lg" style={{ marginTop: theme.spacing(4) }}>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          component={Link}
          to="/alumni"
        >
          Testimonials
        </Button>
        <Grid

          container
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >

          {/* <HeaderSection header={title} subheader={description} /> */}

          <Typography
            component="h3"
            variant="h3"
            align="center"
            // color="inherit"
            gutterBottom
            className={classes.title}
          >
            {title}
          </Typography>
          {linkedIn && <a
            href={linkedIn}
            target="_blank"
            rel="noreferrer"
          >
            <LinkedInIcon
            // className={classes.icon}
            />
          </a>}
          {github && <a
            href={github}
            target="_blank"
            rel="noreferrer"
          >
            <GitHubIcon
              color="primary"
            // className={classes.icon}
            />
          </a>}
        </Grid>
        <Typography
          component="h5"
          variant="h5"
          align="center"
          color="inherit"
          gutterBottom
        >
          Graduated in <strong>{date}</strong> with the <strong>{cohort}</strong> cohort
        </Typography>

        {showPhoto && <Grid
          container
          spacing={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PreviewCompatibleImage
            className={classes.image}
            classes={classes.image}
            imageInfo={{
              image,
            }}
          /></Grid>}
        {/* <Container maxWidth="md" style={{ marginTop: theme.spacing(10) }}> */}
        <PostContent content={content} />
        {/* </Container> */}
        <Grid
          container
          spacing={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" color="textPrimary" component="h5">
            Share this article
          </Typography>
          <Button onClick={linkedinShare} size="large" color="primary">
            <LinkedInIcon fontSize="large" />
          </Button>
          <Button onClick={facebookShare} size="large" color="primary">
            <FacebookIcon fontSize="large" />
          </Button>
        </Grid>
      </Container>
    </React.Fragment >
  );
};

AlumniPostTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.object,
  description: PropTypes.string,
  title: PropTypes.string,
  cohort: PropTypes.string,
  helmet: PropTypes.object,
  post: PropTypes.object,
  date: PropTypes.string,
  showPhoto: PropTypes.bool,
};

const AlumniPost = ({ data, location }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout location={location}>
      <SEO
        location={location}
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt || "nothin’"}
        image={post.frontmatter.frontImage.relativePath}
        pathname={post.fields.slug}
        article
      />
      <AlumniPostTemplate
        location={location}
        content={post.html}
        contentComponent={ContentJustified}
        description={post.frontmatter.description}
        image={post.frontmatter.frontImage}
        showPhoto={post.frontmatter.showPhoto}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        cohort={post.frontmatter.cohort}
        date={post.frontmatter.date}
        github={post.frontmatter.github}
        linkenIn={post.frontmatter.linkedIn}
      />
    </Layout>
  );
};

AlumniPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default AlumniPost;

export const pageQuery = graphql`
  query AlumniPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM YYYY")
        title
        cohort
        github
        linkedin
        description
        tags
        showPhoto
        archived
        frontImage {
          childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }              
          }
        }
      }
    }
  }
`;
