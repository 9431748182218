const styles = (theme) => ({
  "@global": {
    // img: {
    //   display: "block",
    //   marginLeft: "auto",
    //   marginRight: "auto",
    //   maxWidth: "650px",
    //   width: "100%",
    //   [theme.breakpoints.up("sm")]: {
    //     width: "80%",
    //   },
    // },
    h2: {
      textAlign: "center",
    },
    a: {
      textDecoration: "none",
      color: theme.palette ? theme.palette.secondary.main : "#F26363",
      fontWeight: "bold",
    },
  },
});
export default styles;
